import { produce } from 'immer';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { ActionTypes } from './actions';
import { IAction, ListingState } from './types';
import { ANALYTICS_EMPTY_STRING } from 'shared/layouts/Listing/constants';

const PRODUCTS_PER_PAGE = 12;

export const initialState = Object.freeze<ListingState>({
  products: {},
  filters: [],
  isFetching: false,
  isSecondaryFetching: false,
  isFetchingError: false,
  isNotFound: false,
  title: '',
  isBrand: null,
  appliedFilters: { PageSize: PRODUCTS_PER_PAGE, sort: 'popularity' },
  categoryId: '',
  searchTerm: '',
  currentPage: 1,
  count: 0,
  banner_images: [],
  topCollection: [],
  meta_data: {},
  tiptiles: [],
  errorId: '',
  errorStatusCode: '',
  ui_message: '',
  footerHTML: '',
  query_expansion_term: ANALYTICS_EMPTY_STRING,
  query_expansion_type: ANALYTICS_EMPTY_STRING,
  filterGuides: {},
  categoryURL: '',
  adPlatformData: {
    pageType: '',
    pageData: '',
    positionMap: {},
    isFetching: false,
    isFetchingError: false,
    isNotFound: false,
  },
  adplatformWidgets: {},
  adplatformWidgetsSearch: [],
  crossPlatformRedirectConfig: { enabled: false },
  kids_filters: null,
  entityData: {
    entityCoverage: 0,
    entityType: '',
    entityValues: '',
  },
  trackingMetaData: {
    merchandising_details: {
      is_hybrid_page: false,
      has_pinned_products: false,
      has_tiptiles: false,
      has_banners: false,
    },
  },
  intl_url: {},
  bestPrice: {},
  fomoTag: { end_time: 0, label: '' },
  quickFilters: [],
  breadcrumbs: [],
});

const transformProducts = (
  intialProducts: Listing.IProduct[],
  page: number,
  tiptiles: Listing.ITiptile[] = []
) => {
  let initialCount = (page - 1) * PRODUCTS_PER_PAGE;

  let tiptileIncluded = 0;
  tiptiles.forEach((tipTile) => {
    if (tipTile.position <= initialCount) {
      tiptileIncluded++;
    }
  });

  initialCount = initialCount + tiptileIncluded;

  const products = intialProducts.map((pd) => {
    return {
      ...pd,
      lcp: initialCount++,
    };
  });
  return products;
};

const transformGuides = (guides: Listing.IFilterGuide[]) => {
  const filterGuides: Listing.ITransfromedGuide = {};
  if (isEmpty(guides)) {
    return filterGuides;
  }
  guides.map((guide) => {
    guide.position.map((position) => {
      filterGuides[Number(position)] = guide;
    });
  });
  return filterGuides;
};

const transformAdplatformData = (adplatformResponse) => {
  return {
    pageType: get(adplatformResponse, 'page-type', ''),
    pageData: get(adplatformResponse, 'page-data', ''),
    positionMap: get(adplatformResponse, 'position_map', {}),
    title: get(adplatformResponse, 'title', ''),
    sub_title: get(adplatformResponse, 'sub_title', ''),
    isFetching: false,
    isFetchingError: false,
    isNotFound: false,
  };
};

const transformAdplatformWidgets = ({
  widgets,
  positionMap,
}: Listing.ITransformAdplatformProps) => {
  const adplatformWidgets: Listing.IAdplaformWidgets = {};
  widgets.forEach((widget) => {
    const { inventory_page_section } = widget;
    const index = positionMap[inventory_page_section];
    if (index === undefined) {
      return;
    }
    adplatformWidgets[index] = widget;
  });
  return adplatformWidgets;
};

export default (state: ListingState = initialState, action: IAction): ListingState =>
  produce(state, (draft) => {
    const { type, payload } = action;

    switch (type) {
      case ActionTypes.RESET: {
        return initialState;
      }

      case ActionTypes.FETCH_LISTING_INITIAL_DATA_PROGRESS: {
        draft.isFetching = true;
        return;
      }

      case ActionTypes.FETCH_LISTING_DATA_PROGRESS: {
        draft.isSecondaryFetching = true;
        return;
      }

      case ActionTypes.SET_CATEGORY_ID: {
        draft.categoryId = payload;
        return;
      }

      case ActionTypes.SET_SEARCH_TERM: {
        draft.searchTerm = payload;
        return;
      }

      case ActionTypes.FETCH_LISTING_INITIAL_DATA_SUCCESS: {
        const { response, page } = payload;

        draft.isFetching = false;
        draft.isFetchingError = false;
        draft.isNotFound = false;

        const initialProducts = state.products;
        if (!isEmpty(initialProducts)) {
          draft.products = {
            ...draft.products,
            [String(page)]: transformProducts(response.products, page, response.tiptiles),
          };
        } else {
          draft.products = {
            [String(page)]: transformProducts(response.products, page, response.tiptiles),
          };
        }

        draft.kids_filters = response.kids_filters || null;
        draft.quickFilters = response.quick_filters || [];
        draft.filters = response.filters || [];
        draft.title = response.title || '';
        draft.count = response.count;
        draft.banner_images = response.banner_images;
        draft.categoryId = response.categoryId;
        draft.meta_data = response.meta_data;
        draft.tiptiles = response.tiptiles;
        draft.errorId = '';
        draft.topCollection = response.nowTrending || [];
        draft.ui_message = response.ui_message || '';
        draft.footerHTML = response.block_data || '';
        draft.query_expansion_term = response.query_expansion_term || ANALYTICS_EMPTY_STRING;
        draft.query_expansion_type = response.query_expansion_type || ANALYTICS_EMPTY_STRING;
        draft.filterGuides = transformGuides(response.guides);
        draft.categoryURL = response.categoryURL;
        draft.adPlatformData = transformAdplatformData(response.ad_platform_data);
        draft.isBrand = response.is_brand;
        draft.entityData = {
          entityCoverage: response.entity_coverage,
          entityType: response.entity_type,
          entityValues: response.entity_values,
        };
        draft.fomoTag = response.fomo_tag || {};
        draft.trackingMetaData = response.tracking_metadata;
        draft.intl_url = response.intl_url || {};
        draft.breadcrumbs = response.breadcrumbs || [];
        return;
      }

      case ActionTypes.FETCH_LISTING_DATA_SUCCESS: {
        const { response, page } = payload;
        draft.products = {
          ...draft.products,
          [String(page)]: transformProducts(response.products, page, response.tiptiles),
        };
        draft.filterGuides = transformGuides(response.guides);
        draft.count = response.count;
        draft.isFetching = false;
        draft.isSecondaryFetching = false;
        draft.isFetchingError = false;
        draft.isNotFound = false;
        return;
      }

      case ActionTypes.FETCH_LISTING_INITIAL_DATA_FAILED: {
        const { errorId, statusCode } = payload;
        draft.isFetching = false;
        draft.isNotFound = false;
        draft.isFetchingError = true;
        draft.errorId = errorId;
        draft.errorStatusCode = statusCode;
        return;
      }

      case ActionTypes.FETCH_LISTING_DATA_FAILED: {
        // TODO - Figure what to do if a paginated request failed
        return;
      }

      case ActionTypes.FETCH_LISTING_DATA_NOT_FOUND: {
        draft.isFetching = false;
        draft.isNotFound = true;
        draft.isFetchingError = false;
        draft.errorId = payload;
        return;
      }

      case ActionTypes.SET_FILTERS: {
        draft.appliedFilters = {
          ...payload,
        };

        // RESET Page after filters changed to First
        draft.currentPage = 1;
        return;
      }

      case ActionTypes.APPLY_FILTERS: {
        if (isEmpty(payload.values)) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [payload.filterKey]: removed, ...rest } = state.appliedFilters;
          draft.appliedFilters = {
            ...rest,
          };
        } else {
          draft.appliedFilters = {
            ...state.appliedFilters,
            [payload.filterKey]: payload.values,
          };
        }

        // RESET Page after filters changed to First
        draft.currentPage = 1;
        return;
      }

      case ActionTypes.UPDATE_CURRENT_PAGE: {
        draft.currentPage = payload;
        return;
      }

      case ActionTypes.UPDATE_SORT_BY: {
        draft.appliedFilters.sort = payload;
        return;
      }

      case ActionTypes.FETCH_ADPLATFORM_WIDGETS_SUCCESS: {
        const {
          data: { result },
          positionMap,
        } = payload;

        draft.adPlatformData.isFetching = false;
        draft.adPlatformData.isFetchingError = false;
        draft.adPlatformData.isNotFound = false;

        if (typeof positionMap === 'object' && Object.keys(positionMap).length) {
          draft.adplatformWidgets = transformAdplatformWidgets({
            widgets: result,
            positionMap,
          });
          return;
        }
        draft.adplatformWidgetsSearch = result;
        return;
      }

      case ActionTypes.FETCH_ADPLATFORM_WIDGETS_CLEAR: {
        draft.adplatformWidgets = {};
        return;
      }

      case ActionTypes.FETCH_ADPLATFORM_WIDGETS_NOT_FOUND: {
        draft.adPlatformData.isFetching = false;
        draft.adPlatformData.isFetchingError = false;
        draft.adPlatformData.isNotFound = true;
        return;
      }

      case ActionTypes.FETCH_ADPLATFORM_WIDGETS_PROGRESS: {
        draft.adplatformWidgets = {};
        draft.adplatformWidgetsSearch = [];
        draft.adPlatformData.isFetching = true;
        draft.adPlatformData.isFetchingError = false;
        draft.adPlatformData.isNotFound = false;
        return;
      }

      case ActionTypes.FETCH_ADPLATFORM_WIDGETS_FAILED: {
        draft.adPlatformData.isFetching = false;
        draft.adPlatformData.isFetchingError = true;
        draft.adPlatformData.isNotFound = false;
        return;
      }

      case ActionTypes.TRIGGER_CROSS_PLATFORM_REDIRECT: {
        draft.crossPlatformRedirectConfig = payload;
        return;
      }

      case ActionTypes.RESET_CROSS_PLATFORM_REDIRECT: {
        draft.crossPlatformRedirectConfig = { enabled: false };
        return;
      }

      case ActionTypes.SET_BEST_PRICE: {
        draft.bestPrice = { ...draft.bestPrice, ...payload };
        return;
      }
    }
  });
